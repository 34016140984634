<template>
  <v-app id="login">
    <app-snack-bar></app-snack-bar>
    <v-main class="login-page">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm4 md4 lg3>
            <v-card
              class="app-card app-card--without-border pa-3 login-page-card"
              max-width="365"
            >
              <v-form
                ref="form"
                lazy-validation
                @submit.prevent="onSubmit"
                class="login-page-form app-form"
              >
                <v-card-text class="pt-0 pb-0">
                  <div class="layout column align-center">
                    <img
                      src="../assets/images/logo.svg"
                      alt="Logo Sistransce"
                      class="login-page-form__logo"
                    />
                    <h5 class="login-page-form__title mb-5">
                      Confirme o email para acessar o sistema
                    </h5>
                  </div>
                  <v-text-field
                    prepend-inner-icon="email"
                    label="Email *"
                    type="text"
                    outlined
                    v-model="formModel.email"
                    :rules="[rules.required]"
                    color="#067B26"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    block
                    x-large
                    depressed
                    type="submit"
                    color="#067B26"
                    class="login-page-form__submit white--text"
                    :loading="loading"
                    >Confirmar</v-btn
                  >
                </v-card-actions>
                <section class="loading" v-if="loading"></section>
              </v-form>
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="#067B26"
              ></v-progress-linear>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import GlobalStore from "@/store/modules/global";
import AppSnackBar from "@/components/AppSnackBar";
import AuthenticationService from "@/services/authentication.service";
import CompanyService from "@/services/company.service";

import { required } from "vuelidate/lib/validators";
import { throwError } from "rxjs";

export default Vue.extend({
  components: {
    AppSnackBar
  },
  data: () => ({
    loading: false,
    formModel: {
      email: ""
    },
    rules: {
      required: value => !!value || "Esse campo deve ser preenchido"
    },
    error: "",
    returnUrl: ""
  }),
  created() {
    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  methods: {
    onSubmit() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.loading = true;
        CompanyService.update(this.user.id, this.formModel).subscribe(
          response =>
            GlobalStore.setSnackBar({
              show: true,
              message: "Entrou no sistemas com sucesso.",
              type: "success"
            }),
          this.$router.push({ name: "home" }),
          error => {
            throwError(error);
            this.loading = false;
          }
        );
      }
    }
  },
  computed: {
    user: () => GlobalStore.user || {}
  }
});
</script>

<style scoped lang="scss">
#login {
  .login-page {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/images/background.png") #f1f1f1;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-attachment: fixed;
    background-size: contain;

    &-card {
      padding: 30px;
      margin: auto;
    }

    &-form {
      margin-top: 10px;
      margin-bottom: 10px !important;

      &__logo {
        width: 82%;
        margin: 0px auto 20px auto;
        display: table;
      }

      &__title {
        text-align: center;
        font-weight: 400;
        margin: 5px auto;
      }

      &__cadastro {
        text-align: center;
        display: table;
        margin: 20px auto auto auto;
        color: #424242;
      }
    }
    .text-right {
      display: table;
      margin-right: 0;
      width: 100%;
    }
  }
}
</style>
