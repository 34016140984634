import { CompanyAPI } from "@/api/company.api";
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { Company } from '@/models/company';

const api = new CompanyAPI()

const update = (id: number, body: Company): AxiosObservable<Company> => api.update(id, body)

export default {
  update
}
